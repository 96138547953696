@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }

  body {
    @apply font-sans;

    color: theme(colors.foreground);
    background-color: theme(colors.background);
  }

  ::selection {
    color: theme(colors.background);
    background-color: theme(colors.foreground);
  }

  /* TODO: load fonts here with font-face */
  #ot-sdk-btn-floating.ot-floating-button button {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@layer utilities {
  .rounded-tl-br {
    @apply rounded-bl-6 rounded-tr-6;
  }

  .hide-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 11 */
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari */
  }

  .border-dashed-custom {
    border-style: dashed;
    border-width: 1px;
    border-color: gray;
    border-image: repeating-linear-gradient(
        90deg,
        hsl(var(--twc-accent-subtle) / 1),
        hsl(var(--twc-accent-subtle) / 1) 4px,
        transparent 4px,
        transparent 14px
      )
      10;
  }
}
